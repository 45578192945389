<template>
  <component :is="commonComponent" id="disclamimer" title="Disclamimer">
    <section id="disclamimer-content" class="pc_padding">
      <div class="common-content-row">
        <ol>
          <li>
            The information provided on {{ domainConfig['IAmURL'] }} is intended for general
            informational
            purposes only. By using this
            website, you agree to the following terms:<ol>
              <li>
                <strong>Accuracy of Information</strong>: While we strive to ensure that the content
                on our site is accurate
                and up-to-date, we make no guarantees regarding the completeness, accuracy,
                reliability, or suitability of the
                information. The content is provided &quot;as is&quot; without warranties of any
                kind.
              </li>
              <li>
                <strong>Use of Content</strong>: Any reliance you place on such information is
                strictly at your own risk. We
                recommend verifying any information obtained from this site before making decisions
                or taking action based on
                it.
              </li>
              <li>
                <strong>External Links</strong>: Our website may contain links to external sites
                that are not operated by us.
                These links are provided for your convenience and do not signify our endorsement of
                the content on those sites.
                We have no control over the nature, content, and availability of those sites and
                accept no responsibility for
                them.
              </li>
              <li>
                <strong>Limitation of Liability</strong>: In no event will
                {{ domainConfig['IAmURL'] }}, its
                owners, or affiliates be
                liable for any loss or damage, including without limitation, indirect or
                consequential loss or damage, arising
                from the use of this website.
              </li>
              <li>
                <strong>Health and Spiritual Advice</strong>: The content on this site is not
                intended to provide health,
                legal, or spiritual advice. For specific advice, please consult a qualified
                professional.
              </li>
              <li>
                <strong>Changes to Content</strong>: We reserve the right to make changes to the
                website and its content at
                any time without notice. This includes updates, modifications, or removal of
                content.
              </li>
              <li>
                <strong>User Responsibility</strong>: Users are responsible for ensuring that any
                products, services, or
                information available through this website meet their specific requirements.
              </li>
              <li>
                <strong>Jurisdiction</strong>: This disclaimer is governed by the laws of the
                jurisdiction in which
                {{ domainConfig['IAmURL'] }} operates, and any disputes will be subject to the
                exclusive
                jurisdiction of the courts of that
                jurisdiction.
              </li>
              <li>
                <strong>Contact Us</strong>: If you have any questions or concerns about this
                disclaimer, please contact us at
                <a class="footer-item-content-item"
                  :href="`mailto:${domainConfig['IAMEMAIL']}`">{{ domainConfig['IAMEMAIL'] }}</a>.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Disclaimer | ${this.domainConfig["IAmURL"]}`,
      meta: [
        {
          name: 'description',
          content: `Access the Bible anytime with ${this.domainConfig['IAmURL']}. Enjoy seamless navigation for study and reflection.`,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>